import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VigilanteGuard } from './vigilante.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'principal',
    loadChildren: () => import('./principal/principal.module').then( m => m.PrincipalPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'sede',
    loadChildren: () => import('./sede/sede.module').then( m => m.SedePageModule)
  },
  {
    path: 'reporte',
    loadChildren: () => import('./reporte/reporte.module').then( m => m.ReportePageModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./monitoreo/monitoreo.module').then( m => m.MonitoreoPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'variables',
    loadChildren: () => import('./variables/variables.module').then( m => m.VariablesPageModule)
  },
  {
    path: 'yutaho',
    loadChildren: () => import('./yutaho/yutaho.module').then( m => m.YutahoPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'despacho',
    loadChildren: () => import('./despacho/despacho.module').then( m => m.DespachoPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'sensor',
    loadChildren: () => import('./sensor/sensor.module').then( m => m.SensorPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: '**',
    redirectTo: 'login'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
